export const networkList = ['arbitrum', 'base', 'binance', 'ethereum', 'optimism', 'polygon', 'avalanche'];

export const EXPLORER_URLS: Record<typeof networkList[number], string> = {
	'optimism': 'https://optimistic.etherscan.io/',
	'ethereum': 'https://etherscan.io/',
	'polygon': 'https://polygonscan.com/',
	'arbitrum': 'https://arbiscan.io/',
	'avalanche': 'https://cchain.explorer.avax.network/',
	'binance': 'https://bscscan.com/',
	'base': 'https://basescan.org/',
};