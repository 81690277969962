import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { setAuthToken } from './apollo';
import { Button } from './components/ui/button';
import { isValidAddress, shortenAddress } from './utils/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Popover, PopoverContent, PopoverTrigger } from './components/ui/popover';
import { MetaMaskIcon } from '@ui-kit/Icons';
import { CheckIcon, CoinsIcon, Edit3Icon, UploadCloudIcon, UserIcon, UserRoundPlus } from 'lucide-react';
import { gql, useApolloClient } from '@apollo/client';
import { Loader } from '@utils';
import { GetAccountList, MyUser } from '@gql';
import { Skeleton } from './components/ui/skeleton';
import { useState } from 'react';
import { toast } from 'sonner';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from './components/ui/dialog';
import { Input } from './components/ui/input';
import clsx from 'clsx';
import { useCurrentAccountName } from './hooks/useCurrentAccountName';

function ComputeUnits() {
  // TODO: get from source
  const data = '∞';

  return (
    <div className="flex flex-col items-end">
      <Button variant="ghost" className="gap-2 flex items-center p-0 h-fit">
        <CoinsIcon className="w-4 h-4" />
        <span className="font-medium">Credit</span>
      </Button>
      <span className="text-sm leading-4 w-fit">{data} CU</span>
    </div>
  );
}

function Storage() {
  // TODO: get from source
  const storage = 120;
  const total = 200;

  return (
    <div className="flex flex-col items-end">
      <Button variant="ghost" className="gap-2 flex items-center p-0 h-fit">
        <UploadCloudIcon className="w-4 h-4" />
        <span className="font-medium">Storage</span>
      </Button>
      <span className="text-sm leading-4 w-fit">{`${storage}Go / ${total}Go`}</span>
    </div>
  );
}

function User() {
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const apollo = useApolloClient();

  const currentUser = Loader.query<MyUser>(
    gql`
      query MyUser {
        myUser {
          address
          name
          id
        }
      }
    `,
    {
      refetchWhenChanges: [refreshTrigger],
    }
  ).map(data => data.myUser);

  currentUser.onOk([isEditMode], (user) => {
    if (isEditMode) {
      setNameInput(user?.name ?? '')
    }
  });

  const handleNameBlur = () => {
    setIsEditMode(false);
    setIsNameValid(true);
  };

  const renameUser = async () => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation RenameMyUser($name: String!) {
            renameMyUser(name: $name)
          }
        `,
        variables: {
          name: nameInput,
        },
      });
      toast.success('User renamed!');
      setRefreshTrigger(prev => prev + 1);
    } catch (e) {
      console.error('Failed to rename user', e);
      toast.error('Failed to rename user');
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && isNameValid) {
      setIsEditMode(false);
      renameUser();
    }
  }

  const handleNameChange = (value: string) => {
    setNameInput(value);
    setIsNameValid(/^[\w\s0-9_]{3,50}$/.test(value));
  }

  return (
    currentUser.match
      .loadingOrSkipped(() =>
        <div className="mt-2.5 flex flex-col gap-0.5">
          <Skeleton className="h-7 w-40" />
          <Skeleton className="h-[18px] w-20" />
        </div>
      )
      .error(() => null)
      .ok(user =>
        <div>
          <div className="flex gap-2 pt-2.5 items-center">
            {
              isEditMode
                ? (
                  <>
                    <Input
                      className="w-fit h-7"
                      autoFocus
                      value={nameInput}
                      onChange={e => handleNameChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      onBlur={handleNameBlur}
                    />
                    <CheckIcon role="button" className="w-4 h-4 text-muted-foreground" onMouseDown={isNameValid ? renameUser : undefined} />
                  </>
                )
                : (
                  <>
                    <div className="text-base leading-7 font-bold">
                      {user?.name === user?.address ? shortenAddress(user?.address) : user?.name}
                    </div>
                    <Edit3Icon role="button" className="w-4 h-4 text-muted-foreground" onClick={() => setIsEditMode(true)} />
                  </>
                )
            }
          </div>
          {!isNameValid && isEditMode && <span className="text-destructive">3 to 50 characters</span>}
          {user?.address !== user?.name && <span className="text-muted-foreground text-sm">{shortenAddress(user?.address)}</span>}
          <div className="flex items-center gap-2.5">
            <MetaMaskIcon className="h-4 w-4" />
            <span className="text-sm text-muted-foreground">Web3 wallet</span>
          </div>
        </div>
      )

  )
}

function UserMenu() {
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);
  const [accountName, setAccountName] = useState('');

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const apollo = useApolloClient();
  const navigateTo = useNavigate();

  const accounts = Loader.query<GetAccountList>(
    gql`
      query GetAccountList {
        currentAccount {
          id
        }
        accounts {
          id
          name
        }
      }
    `,
    {
      refetchWhenChanges: [refreshTrigger],
    }
  ).map(x => x.accounts.map(account => ({
    name: account.name,
    id: account.id,
    isCurrentAccount: account.id === x.currentAccount.id,
  })).sort((a, b) => a.name.localeCompare(b.name)));

  const switchAccount = async (accountId: string) => {
    try {
      const newToken = await apollo.mutate({
        mutation: gql`
          query SwitchAccount($accountId: AccountId!) {
            switchAccount(accountId: $accountId)
          }
        `,
        variables: {
          accountId,
        },
      });
      setAuthToken(newToken.data.switchAccount);
      setRefreshTrigger(prev => prev + 1);
      setIsPopoverOpen(false);
      navigateTo('/');
    } catch (e) {
      console.error('Failed to switch account', e);
      toast.error('Failed to switch account');
    }
  }

  const addNewAccount = async () => {
    try {
      setIsSaving(true);
      const newAccount = await apollo.mutate({
        mutation: gql`
          mutation CreateAccount($name: String!) {
            createAccount(name: $name)
          }
        `,
        variables: {
          name: accountName,
        },
      });
      setAuthToken(newAccount.data.createAccount);
      toast.success('New account added!');
      setAccountName('');
      setIsAccountDialogOpen(false);
      setIsSaving(false);
      setRefreshTrigger(prev => prev + 1);
      navigateTo('/');
    } catch (e) {
      console.error('Failed to add account', e);
      toast.error('Failed to add account');
    }
  };

  return (
    <>
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild className="self-center">
          <Button variant="secondary" className="w-9 h-9 !p-0">
            <UserIcon className="w-4 h-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col gap-4 px-6 py-4 -translate-x-3 translate-y-1">
          <User />
          <div className="flex flex-col gap-1.5">
            {accounts
              .match
              .loadingOrSkipped(() =>
                <>
                  <Skeleton className="w-40 h-4" />
                  <Skeleton className="w-40 h-4" />
                  <Skeleton className="w-40 h-4" />
                </>
              )
              .error(() => null)
              .ok(_accounts => _accounts
                .map(({ id, isCurrentAccount, name }) =>
                  <div
                    key={id}
                    onClick={() => isCurrentAccount ? undefined : switchAccount(id)}
                    role="button"
                    className={clsx("py-px px-2 rounded-sm flex justify-between items-center", isCurrentAccount ? 'bg-primary bg-opacity-50' : 'hover:bg-accent')}
                  >
                    {isValidAddress(name) ? shortenAddress(name) : name}
                  </div>
                ))
            }
          </div>
          <Link onClick={() => setIsPopoverOpen(false)} className="flex items-center gap-2" role="button" to={'/account-settings'}>
            <UserIcon className="w-4 h-4" />
            Account settings
          </Link>
          <div className="flex items-center gap-2" role="button" onClick={() => setIsAccountDialogOpen(true)}>
            <UserRoundPlus className="w-4 h-4" />
            Create a new account
          </div>
          <Button onClick={() => setAuthToken(null)}>Logout</Button>
        </PopoverContent>
      </Popover>
      <Dialog open={isAccountDialogOpen} onOpenChange={setIsAccountDialogOpen} modal>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New account</DialogTitle>
            <DialogDescription>Enter account name</DialogDescription>
          </DialogHeader>
          <div>
            <Input
              className="h-10 bg-secondary border-none rounded-xl"
              value={accountName}
              onChange={e => setAccountName(e.target.value)}
            />
          </div>
          <DialogFooter>
            <Button disabled={!accountName || isSaving} size="lg" type="submit" onClick={addNewAccount}>
              Add
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBackClick = () => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    navigate(location.pathname.substring(0, lastSlashIndex));
  };

  return (
    <Button className="flex items-center gap-2" variant="secondary" onClick={handleGoBackClick}>
      <ArrowLeftIcon className="w-4 h-4" />
      <span className="text-base">Back</span>
    </Button>
  );
}

export function Header() {
  const { name: currentAccountName } = useCurrentAccountName();

  return (
    <div className="flex items-center gap-2.5 pb-4 border-b h-[52px]">
      <BackButton />
      <div className="flex-1 justify-end flex gap-4 h-full items-center">
        <div className="flex gap-4 items-center h-full">
          <ComputeUnits />
          {/* hidden for now */}
          {/* <Separator orientation="vertical" />
          <Storage /> */}
        </div>
        {currentAccountName && isValidAddress(currentAccountName) ? shortenAddress(currentAccountName) : currentAccountName}
        <UserMenu />
      </div>
    </div>
  );
}
