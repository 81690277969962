import { TableHeader, TableRow, TableHead, TableBody, TableCell, Table } from '@/components/ui/table';
import { gql } from '@apollo/client';
import { ScriptDbs } from '@gql';
import { Loader } from '@utils';
import { useNavigate } from 'react-router-dom';
import { ChartSummary } from './ChartSummary';
import { TableLoader } from '@/components/ui/skeleton';
import { EmptyListAlert, ErrorAlert } from '@/components/ui/alert';
import { ScrollArea } from '@/components/ui/scroll-area';

export function DatabaseList() {
  const navigateTo = useNavigate();

  const scripts = Loader.query<ScriptDbs>(
    gql`
      query ScriptDbs {
        scriptDbs {
          name
          graph
          count
          size
          requestsToday
        }
      }
    `,
  ).map(data => data.scriptDbs);

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
        <div className="flex-1 py-1">
          <h4>Database</h4>
        </div>
      </div>
      {
        scripts.match
          .loadingOrSkipped(() => <TableLoader />)
          .error(e => <ErrorAlert name={e.name} message={e.message} />)
          .ok(list => (
            list.length === 0 ? <EmptyListAlert message="No database yet" /> :
              <ScrollArea>
                <Table className="border-separate border-spacing-y-2">
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead>Size</TableHead>
                      <TableHead>Count</TableHead>
                      <TableHead>Request Today</TableHead>
                      <TableHead>24h chart</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {list.map(database => (
                      <TableRow data-type="primary" key={database.name} onClick={() => navigateTo(`./${database.name}`)}>
                        <TableCell className="font-bold">{database.name}</TableCell>
                        <TableCell>{database.size}</TableCell>
                        <TableCell>{database.count}</TableCell>
                        <TableCell>{database.requestsToday}</TableCell>
                        <TableCell className='max-w-10'>
                          <div className='h-12'>
                            <ChartSummary chart={database.graph as unknown as unknown[]} />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ScrollArea>
          ))
      }
    </div>
  );
}
