import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { PayloadTitle } from "@/components/ui/payload-title";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { useMemo, useState } from "react";
import { Filter } from "../filter/filterdef";
import { CodeEditor } from "../CodeEditor";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { FilterEditor } from "../filter/FilterEditor";
import { useScript } from "../useScript";
import { toast } from "sonner";
import { gql, useApolloClient } from "@apollo/client";
import { Link } from "react-router-dom";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { FormField } from "@/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { networkList } from "@/constants/networks";
import { Input } from "@/components/ui/input";
import { TestScript, TestScriptInput } from "@gql";
import { Loader } from "@utils";
import { Skeleton } from "@/components/ui/skeleton";
import { ErrorBlock } from "@ui-kit/ErrorBlock";
import { TestResult } from "../tester/ScriptTester";

type ForkScript = {
  name?: string;
  code: string;
  filter?: Filter;
  network?: string;
  block?: string;
  createAddressLists?: {
    name: string;
    addresses: HexString[];
  }
}

type ForkProps = {
  decodedHash: string;
}

function getErrorMessage(message: string) {
  if (message.includes('does not exist, or is too old')) {
    return 'This block does not exist or is too old. Simulations only work for recent blocks yet.';
  }
  return message;
}

export function Fork({ decodedHash }: ForkProps) {
  const [currentContent, setcurrentContent] = useState<'script' | 'filters'>('script');
  const script = useMemo<ForkScript>(() => JSON.parse(decodedHash), [decodedHash]);
  const filtersCount = script.filter?.length;
  const [block, setBlock] = useState<string>(script.block ?? '');
  const [network, setNetwork] = useState<string>(script.network ?? networkList[0]);
  const [testScriptInput, setTestScriptInput] = useState<TestScriptInput | null>({
    block: script.block ?? '',
    filter: script.filter as any,
    network: script.network ?? networkList[0],
    ts: script.code,
  });
  const [testTrigger, setTestTrigger] = useState(0);
  const apollo = useApolloClient();
  const { onSave } = useScript();

  const handleSimulateClick = () => {
    setTestTrigger(prev => prev + 1);
    setTestScriptInput({
      block,
      network,
      filter: script.filter as any,
      ts: script.code,
    })
  }

  const testResults = Loader.useWrap([testScriptInput, testTrigger] as const)
    .map(([_testScriptInput]) => (_testScriptInput ? _testScriptInput : Loader.skipped))
    .query<TestScript>([testTrigger],
      gql`
        query TestForkedScript($input: TestScriptInput!) {
          testScript(input: $input) {
            events
            state
          }
        }
      `,
      input => ({
        input,
      }),
    )
    .map(response => response.testScript);

  const handleFork = async () => {
    try {
      if (script.createAddressLists) {
        await apollo.mutate({
          mutation: gql`
            mutation CreateNewAddressList($name: String!) {
              newAddressList(name: $name)
            }
          `,
          variables: { name: script.createAddressLists.name },
        });
        await apollo.mutate({
          mutation: gql`
            mutation AddressListAdd($name: String!, $items: [HexString!]!) {
              addressListAdd(name: $name, items: $items)
            }
          `,
          variables: {
            name: script.createAddressLists.name,
            items: script.createAddressLists.addresses,
          },
        });
      }
      await onSave({
        name: script.name ?? `Forked - ${new Date().toISOString()}`,
        code: script.code, filter:
          script.filter
      });
    } catch (e) {
      toast.error('Failed to fork script');
    }

  }

  return (
    <TooltipProvider delayDuration={100}>
      <Dialog open>
        <DialogContent hasClose={false} className="max-w-[90vw] max-h-[90vh] overflow-hidden flex h-full flex-col">
          <DialogHeader>
            <DialogTitle>Fork {script.name && <span>{`"${script.name}"`}</span>}</DialogTitle>
            <DialogDescription>
              Fork this script & deploy
            </DialogDescription>
          </DialogHeader>
          <ResizablePanelGroup direction="horizontal" className="h-full w-full rounded-xl bg-secondary flex p-2 gap-4">
            <ResizablePanel defaultSize={(2 / 3) * 100}>
              <Accordion
                type="single"
                value={currentContent}
                className="h-full w-full rounded-xl bg-secondary"
              >
                <AccordionItem value="script" className="border-b-0">
                  <PayloadTitle
                    title="Script"
                    className="rounded-t-xl border-b"
                    actions={
                      filtersCount ?
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              size="icon"
                              variant="secondary"
                              tabIndex={-1}
                              onClick={() => setcurrentContent(currentContent === 'script' ? 'filters' : 'script')}
                            >
                              {currentContent === 'script' ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {currentContent === 'script' ? 'Collapse' : 'Expand'}
                          </TooltipContent>
                        </Tooltip>
                        : undefined
                    }
                  />
                  <AccordionContent data-overflow-behavior="visible" className="pb-0">
                    <div className="h-[50vh]">
                      <CodeEditor readOnly code={script.code} />
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="filters" className="border-b-0">
                  <PayloadTitle
                    title={
                      <div className='flex gap-2 items-center'>
                        {filtersCount ? 'Only execute if' : 'No filters'}
                        {filtersCount ? <Badge className='rounded-full aspect-square'>{filtersCount}</Badge> : null}
                      </div>}
                    className={cn(currentContent === 'filters' ? 'border-b' : 'border-t rounded-b-xl')}
                    actions={
                      filtersCount ?
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              size="icon"
                              variant="secondary"
                              tabIndex={-1}
                              onClick={() => setcurrentContent(currentContent === 'filters' ? 'script' : 'filters')}
                            >
                              {currentContent === 'filters' ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {currentContent !== 'script' ? 'Collapse' : 'Expand'}
                          </TooltipContent>
                        </Tooltip>
                        : undefined
                    }
                  />
                  <AccordionContent className="pb-0">
                    <ScrollArea className="w-full">
                      <div className="h-[50vh]">
                        <FilterEditor filter={script.filter ?? [{}]} readOnly />
                      </div>
                      <ScrollBar orientation="horizontal" />
                    </ScrollArea>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </ResizablePanel>
            <ResizableHandle className="w-[7px]" withHandle />
            <ResizablePanel defaultSize={(1 / 3) * 100} className="flex flex-col gap-4">
              <h2 className="text-sm font-medium">Test on block</h2>
              <FormField label="Network">
                <Select onValueChange={setNetwork} value={network}>
                  <SelectTrigger className="h-10 bg-muted border-none rounded-md capitalize cursor-pointer">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {networkList.map(network => (
                      <SelectItem key={network} value={network}>
                        {network}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormField>
              <FormField label="Block">
                <Input
                  value={block}
                  onChange={value => setBlock(value.target.value)} className="bg-muted h-10"
                />
              </FormField>
              <Button variant="secondary" onClick={handleSimulateClick}>
                Simulate
              </Button>
              <ScrollArea>
                {testResults.match
                  .skipped(() => null)
                  .loading(() =>
                    <Skeleton className="h-20 w-full" />
                  )
                  .error((e) => <ErrorBlock title={e.name} error={getErrorMessage(e.message)} />)
                  .ok(_result => (
                    <TestResult network={network} blockHash={block.trim()} scriptId={undefined} result={_result} />
                  ))
                }
              </ScrollArea>
            </ResizablePanel>
          </ResizablePanelGroup>
          <DialogFooter>
            <Link to="/" replace>
              <Button variant="secondary">Cancel</Button>
            </Link>
            <Button autoFocus type="submit" onClick={handleFork}>Fork</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TooltipProvider>
  )
}
