import { useCurrentAddress } from "@/apollo";
import { ErrorAlert } from "@/components/ui/alert";
import { AlertDialogHeader, AlertDialogFooter, AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TableLoader } from "@/components/ui/skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useCurrentAccountName } from "@/hooks/useCurrentAccountName";
import { gql, useApolloClient } from "@apollo/client";
import { GetUserList } from "@gql";
import { Loader } from "@utils";
import clsx from "clsx";
import { CheckIcon, Edit3Icon, PlusSquare, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

export function AccountsList() {
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [addressInput, setAddressInput] = useState('');
  const apollo = useApolloClient();
  const [isSaving, setIsSaving] = useState(false);
  const currentAddress = useCurrentAddress();
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  const { name: currentAccountName, refresh } = useCurrentAccountName();
  const [isEditNameMode, setIsEditNameMode] = useState(false);
  const [name, setName] = useState(currentAccountName ?? '');
  const [isNameValid, setIsNameValid] = useState(true);

  const users = Loader.query<GetUserList>(
    gql`
      query GetUserList {
        currentAccount {
          users {
            address
            name
            id
          }
        }
      }
    `,
    {
      refetchWhenChanges: [refreshTrigger],
    }
  ).map(x => x.currentAccount.users);


  const handleDelete = async (address: HexString) => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation DeleteAccount($userAddress: HexString!, $isMember: Boolean!) {
            setAccountMember(userAddress: $userAddress, isMember: $isMember)
          }
        `,
        variables: {
          userAddress: address,
          isMember: false,
        },
      });
      toast.success('User removed');
      setRefreshTrigger(prev => prev + 1);
    } catch (e) {
      toast.error('Failed to delete user');
    }
  }

  const handleInviteMember = async () => {
    setIsSaving(true);
    try {
      await apollo.mutate({
        mutation: gql`
          mutation InviteMember($userAddress: HexString!, $isMember: Boolean!) {
            setAccountMember(userAddress: $userAddress, isMember: $isMember)
          }
        `,
        variables: {
          userAddress: addressInput,
          isMember: true,
        },
      });
      toast.success('User added');
      setIsSaving(false);
      setRefreshTrigger(prev => prev + 1);
      setAddressInput('');
      setIsInviteMemberModalOpen(false);
    } catch (e) {
      toast.error('Failed to add user');
    }
  }

  const handleNameBlur = () => {
    setName(currentAccountName ?? '');
    setIsEditNameMode(false);
    setIsNameValid(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setIsEditNameMode(false);
      renameAccount();
    }
  }

  const renameAccount = async () => {
    try {
      setIsSaving(true);
      await apollo.mutate({
        mutation: gql`
          mutation RenameAccount($name: String!) {
            renameAccount(name: $name)
          }
        `,
        variables: {
          name,
        },
      });
      toast.success('Account renamed!');
      refresh();
      setIsSaving(false);
    } catch (e) {
      console.error('Failed to rename account', e);
      toast.error('Failed to rename account');
    }
  };

  const handleNameChange = (value: string) => {
    setName(value);
    setIsNameValid(/^[a-z0-9_]{3,30}$/.test(value));
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
        <div className="flex-1 py-1 flex items-center gap-2">
          <h4>Members of account {` `}</h4>
          {isEditNameMode
            ?
            <Input
              className="w-fit h-7"
              autoFocus
              value={name}
              onChange={e => handleNameChange(e.target.value)}
              onKeyDown={handleKeyDown}
              onBlur={isNameValid ? handleNameBlur : undefined}
            />
            : <h4>{currentAccountName}</h4>
          }
          {isEditNameMode ? (
            <CheckIcon 
              role="button" 
              className={clsx("w-4 h-4 text-muted-foreground", !isNameValid && 'opacity-70 cursor-default')} 
              onMouseDown={isNameValid ? renameAccount : undefined}
            />
          ) : (
            <Edit3Icon role="button" className="w-4 h-4 text-muted-foreground" onClick={() => setIsEditNameMode(true)} />
          )}
          {!isNameValid && isEditNameMode && <span className="text-destructive">3 to 30 lowercase alphanumeric only characters</span>}
        </div>
        <Dialog open={isInviteMemberModalOpen} onOpenChange={setIsInviteMemberModalOpen}>
          <DialogTrigger asChild>
            <Button size="lg" className="flex items-center gap-2">
              <PlusSquare className="w-4 h-4" />
              <span>Invite member</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Invite member</DialogTitle>
              <DialogDescription>Enter address</DialogDescription>
            </DialogHeader>
            <div>
              <Input
                className="h-10 bg-secondary border-none rounded-xl"
                value={addressInput}
                onChange={e => setAddressInput(e.target.value)}
              />
            </div>
            <DialogFooter>
              <Button disabled={!addressInput || isSaving} size="lg" type="submit" onClick={() => handleInviteMember()}>
                Add
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {users.match
        .loadingOrSkipped(() => <TableLoader />)
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(_users => (
          <ScrollArea>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {_users.map(({ name, id, address }) => (
                  <TableRow key={id} data-type="secondary">
                    <TableCell className="font-normal text-base leading-7 flex flex-col">
                      <span>{`${name} ${address === currentAddress ? '(me)' : ''}`}</span>
                      {name !== address ? <span className="text-sm text-muted-foreground bor">{address}</span> : null}
                    </TableCell>
                    <TableCell className="text-right pr-5 space-x-2">
                      <AlertDialog>
                        <AlertDialogTrigger disabled={currentAddress === address}>
                          <Button variant="secondary" size="icon" disabled={currentAddress === address}>
                            <Trash2Icon className="w-4 h-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to delete this user?
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => handleDelete(address)}>Confirm</AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        ))}
    </div>
  )
}
