import { useParams } from 'react-router-dom';
import { ExecErrorsList, HistoryList, WebhookErrorsList } from '../editor/HistoryList';
import { TabBar } from '@/components/ui/tab-bar';
import { useState } from 'react';
import { Toggle } from '@/components/ui/toggle';
import { PauseCircleIcon, PlayCircleIcon } from 'lucide-react';

export function Messages() {
  const { scriptId } = useParams<{ scriptId: ScriptId }>();
  const [tab, setTab] = useState('history');
  const [isRealTimeEnabled, setIsRealTimeEnabled] = useState(false);

  return (
    <div className="flex pt-2.5 h-full">
      <div className="flex flex-col w-full h-full">
        <div className="pt-2 pr-2 flex items-center gap-4">
          <TabBar
            className="w-fit"
            activeTabId={tab}
            tabs={[
              { id: 'history', label: ' History' },
              { id: 'last-errors', label: 'Execution errors' },
              { id: 'webhook-errors', label: 'Webhook errors' },
            ]}
            onChange={setTab}
          />
          {(tab === 'history' || tab === 'last-errors') &&
            <Toggle pressed={isRealTimeEnabled} onPressedChange={() => setIsRealTimeEnabled(prev => !prev)}>
              {isRealTimeEnabled ? <PauseCircleIcon className="w-4 h-4" /> : <PlayCircleIcon className="w-4 h-4" />}
              Real time
            </Toggle>
          }
        </div>
        <div className="bg-secondary p-2 rounded-r-xl rounded-b-xl">
          {tab === 'history' && <HistoryList id={scriptId!} isRealTimeEnabled={isRealTimeEnabled} onRealTimeStateChange={setIsRealTimeEnabled} />}
          {tab === 'last-errors' && <ExecErrorsList id={scriptId!} isRealTimeEnabled={isRealTimeEnabled} onRealTimeStateChange={setIsRealTimeEnabled} />}
          {tab === 'webhook-errors' && <WebhookErrorsList id={scriptId!} />}
        </div>
      </div>
    </div>
  );
}
