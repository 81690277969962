import { useMemo, useState } from 'react';
import { AddressConstraintEdit } from '../AddressConstraint';
import { AddressConstraint, LogConstraint } from '../filterdef';
import { ArrayType, EventType, ParsedEvent, SimpleType, StructType } from './parsed-abi-events';
import { FormField } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { PlusIcon } from '@radix-ui/react-icons';
import { EyeIcon } from 'lucide-react';
import { Separator } from '@/components/ui/separator';

export function EventDataConstraint({
  schema,
  constraint,
  onChange,
  readOnly,
}: {
  schema: ParsedEvent;
  constraint: LogConstraint;
  onChange: (c: LogConstraint) => void;
  readOnly?: boolean;
}) {
  return (
    <div className="flex h-full">
      <div className="flex flex-col gap-4 w-full">
        <FormField value={constraint.emittedBy} readOnly={readOnly} label="emitted by" className="pl-4" onClear={() => onChange({ ...constraint, emittedBy: undefined })}>
          <AddressConstraintEdit
            constraint={constraint.emittedBy}
            readOnly={readOnly}
            onChange={v => {
              onChange({
                ...constraint,
                emittedBy: v,
              });
            }}
          />
        </FormField>
        {/* <FormField readOnly={readOnly} label="contains an address matching" className="pl-4" onClear={() => onChange({ ...constraint, anyAddress: undefined })}>
          <AddressConstraintEdit
            constraint={constraint.anyAddress}
            readOnly={readOnly}
            onChange={v => {
              onChange({
                ...constraint,
                anyAddress: v,
              });
            }}
          />
        </FormField> */}
        {schema.args.map((arg, i) => {
          if (isSimpleType(arg.type) && arg.type.name !== 'address') {
            return null;
          }

          const setAddressProp = (path: string, newValue: AddressConstraint | nil) => {
            onChange({ ...constraint, values: { ...constraint.values, [path]: newValue! } });
          };

          const getAddressConstraint = (path: string) => {
            return constraint.values?.[path];
          };

          return (
            <FormField value={getAddressConstraint(arg.name)} readOnly={readOnly} key={arg.name || i} label={`with ${arg.name}`} className="pl-4" onClear={() => setAddressProp(arg.name, undefined)}>
              {isSimpleType(arg.type) && (
                <AddressConstraintEdit
                  readOnly={readOnly}
                  constraint={getAddressConstraint(arg.name)}
                  onChange={v => {
                    setAddressProp(arg.name, v);
                  }}
                />
              )}

              {isStructType(arg.type) && (
                <StructEditor
                  readOnly={readOnly}
                  schema={arg.type}
                  getConstraint={getAddressConstraint}
                  path={arg.name}
                  onChange={setAddressProp}
                  constraint={constraint}
                />
              )}
            </FormField>
          );
        })}
      </div>
    </div>
  );
}

function StructEditor({
  schema,
  getConstraint,
  path,
  onChange,
  readOnly,
  constraint,
}: {
  schema: StructType;
  getConstraint: (path: string) => AddressConstraint | nil;
  path: string;
  onChange: (path: string, v: AddressConstraint | nil) => void;
  readOnly?: boolean;
  constraint?: LogConstraint;
}) {
  return (
    <div className="flex flex-col gap-4 pl-2 border-l">
      {schema.fields.map((field, i) => {
        return (
          <FormField key={field.name || i} readOnly={readOnly} label={`.${field.name}`} onClear={() => onChange?.(`${path}.${field.name}`, undefined)}>
            {isSimpleType(field.type) && field.type.name === 'address' && (
              <AddressConstraintEdit
                readOnly={readOnly}
                constraint={getConstraint(`${path}.${field.name}`)}
                onChange={v => {
                  onChange(`${path}.${field.name}`, v);
                }}
              />
            )}

            {/* {isSimpleType(field.type) && field.type.name === 'string' && (
              <Input 
                placeholder={field.type.name} 
                value={getConstraint(`${path}.${field.name}`)}
                onChange={e => onChange(`${path}.${field.name}`, e.target.value)} 
                className="h-10 bg-secondary border-none rounded-xl" 
              />
            )} */}

            {isStructType(field.type) && (
              <StructEditor
                schema={field.type}
                getConstraint={getConstraint}
                path={`${path}.${field.name}`}
                onChange={onChange}
                readOnly={readOnly}
                constraint={constraint}
              />
            )}

            {isArrayType(field.type) && (
              <ArrayTypeEditor
                schema={field.type}
                getConstraint={getConstraint}
                path={`${path}.${field.name}`}
                onChange={onChange}
                readOnly={readOnly}
                constraint={constraint}
              />
            )}
          </FormField>
        );
      })}
    </div>
  );
}

function getMaxIndex(data: LogConstraint['addresses'], path: string): number {
  const indexRegex = new RegExp(`^${path}\\[(\\d+)\\]`);
  let maxIndex = -1;

  for (const key in data) {
    const match = key.match(indexRegex);
    if (match) {
      const index = parseInt(match[1], 10);
      if (index > maxIndex) {
        maxIndex = index;
      }
    }
  }

  return maxIndex;
}

function ArrayTypeEditor({
  schema,
  getConstraint,
  path,
  onChange,
  readOnly,
  constraint,
}: {
  schema: ArrayType;
  getConstraint: (path: string) => AddressConstraint | nil;
  path: string;
  onChange: (path: string, v: AddressConstraint | nil) => void;
  readOnly?: boolean;
  constraint?: LogConstraint;
}) {
  const maxIndex = useMemo(() => getMaxIndex(constraint?.addresses, path), [constraint?.addresses, path]);
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false);
  const [isUpdatePopoverOpen, setIsUpdatePopoverOpen] = useState(false);
  const [newEntryValue, setNewEntryValue] = useState<Record<string, any>>({});

  const handleValueChange = (path: string, value: any) => {
    const newConstraint = { ...newEntryValue };
    newConstraint[path] = value;
    setNewEntryValue(newConstraint);
  }

  const getFormValueConstraint = (path: string) => {
    return newEntryValue[path];
  }

  const handlePopoverOpenChange = (open: boolean) => {
    if (!open) {
      setNewEntryValue({});
      setIsCreatePopoverOpen(false);
      for (const key in newEntryValue) {
        const fieldKey = key.split(`${path}.`)[1];
        onChange(`${path}[${maxIndex + 1}]${fieldKey ? `.${fieldKey}` : ''}`, newEntryValue[key]);
      }
    } else {
      setIsCreatePopoverOpen(true);
    }
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between gap-4 items-center">
        {/* TODO: better UX/UI  */}
        <Input
          type="number"
          className="h-10 bg-secondary border-none rounded-xl"
          value={currentIndex}
          onChange={e => setCurrentIndex(Number(e.target.value))}
          placeholder="Index"
        />
        {/* update popover */}
        <Popover open={isUpdatePopoverOpen} onOpenChange={setIsUpdatePopoverOpen}>
          <PopoverTrigger asChild>
            <Button size="icon" variant="secondary" className='flex-shrink-0' disabled={typeof currentIndex !== 'number'}>
              <EyeIcon className="w-4 h-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col px-2 rounded-xl w-[319px] border">
            <FormField label={`${path}[${currentIndex}]`}>
              {isSimpleType(schema.of) && schema.of.name === 'address' && (
                <AddressConstraintEdit
                  readOnly={readOnly}
                  constraint={getConstraint(path)}
                  onChange={v => {
                    onChange(path, v);
                  }}
                />
              )}

              {isStructType(schema.of) && (
                <StructEditor
                  schema={schema.of as StructType}
                  getConstraint={getConstraint}
                  path={`${path}[${currentIndex}]`}
                  onChange={onChange}
                  readOnly={readOnly}
                />
              )}

              {/* {isSimpleType(schema.of) && (schema.of.name === 'string' || schema.of.name === 'bytes32' || schema.of.name === 'bytes') && (
                <Input placeholder={schema.of.name} className="h-10 bg-secondary border-none rounded-xl" />
              )}

              {isSimpleType(schema.of) && (schema.of.name === 'int256' || schema.of.name === 'uint256') && (
                <Input type='number' placeholder={schema.of.name} className="h-10 bg-secondary border-none rounded-xl" />
              )}

              {isSimpleType(schema.of) && (schema.of.name === 'bool') && (
                <Switch />
              )} */}
            </FormField>
          </PopoverContent>
        </Popover>
        <Separator orientation="vertical" />
        {/* add popover */}
        <Popover open={isCreatePopoverOpen} onOpenChange={handlePopoverOpenChange}>
          <PopoverTrigger className={cn(readOnly && 'hidden')}>
            <Button size="icon" variant="secondary" className='flex-shrink-0'><PlusIcon className="w-4 h-4" /> </Button>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col px-2 rounded-xl w-[319px] border">
            <FormField label="New item">
              {isSimpleType(schema.of) && schema.of.name === 'address' && (
                <AddressConstraintEdit
                  readOnly={readOnly}
                  constraint={getFormValueConstraint(path)}
                  onChange={v => {
                    handleValueChange(path, v);
                  }}
                />
              )}

              {isStructType(schema.of) && (
                <StructEditor
                  schema={schema.of as StructType}
                  getConstraint={getFormValueConstraint}
                  path={path}
                  onChange={handleValueChange}
                  readOnly={readOnly}
                />
              )}

              {/* {isSimpleType(schema.of) && (schema.of.name === 'string' || schema.of.name === 'bytes32' || schema.of.name === 'bytes') && (
                <Input placeholder={schema.of.name} className="h-10 bg-secondary border-none rounded-xl" />
              )}

              {isSimpleType(schema.of) && (schema.of.name === 'int256' || schema.of.name === 'uint256') && (
                <Input type='number' placeholder={schema.of.name} className="h-10 bg-secondary border-none rounded-xl" />
              )}

              {isSimpleType(schema.of) && (schema.of.name === 'bool') && (
                <Switch />
              )} */}
            </FormField>
          </PopoverContent>
        </Popover>
      </div>
      <div>
      </div>
    </div>
  )
}

function isSimpleType(type: EventType): type is SimpleType {
  return !isArrayType(type) && !isStructType(type);
}

function isArrayType(type: EventType): type is ArrayType {
  return 'of' in type;
}

function isStructType(type: EventType): type is StructType {
  return 'fields' in type;
}
