import { cn } from "@/lib/utils";

type TabulationProps = {
  label: string;
  state: 'active' | 'inactive';
  onClick: VoidFunction;
};

function Tabulation({ label, state = 'active', onClick }: TabulationProps) {
  return (
    <div
      data-state={state}
      onClick={onClick}
      className="flex text-base leading-4 cursor-pointer items-center justify-center font-medium rounded-t-xl p-2.5 h-12 data-[state=active]:bg-secondary data-[state=inactive]:bg-muted data-[state=inactive]:text-muted-foreground"
    >
      {label}
    </div>
  );
}

export type Tab = Pick<TabulationProps, 'label'> & { id: string };

type TabBarProps = {
  tabs: Tab[];
  activeTabId: Tab['id'];
  onChange: (id: Tab['id']) => void;
  className?: string;
};

export function TabBar({ activeTabId, tabs, onChange, className }: TabBarProps) {

  const handleTabulationClick = (id: Tab['id']) => {
    onChange(id);
  };

  return (
    <div className={cn("flex gap-1 w-full", className)}>
      {tabs.map(tab => (
        <Tabulation
          key={tab.id}
          label={tab.label}
          state={tab.id === activeTabId ? 'active' : 'inactive'}
          onClick={() => handleTabulationClick(tab.id)}
        />
      ))}
    </div>
  );
}
