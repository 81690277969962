import {
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { gql, useApolloClient } from '@apollo/client';
import { GetAddressLists } from '@gql';
import { Loader } from '@utils';
import { useNavigate } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { Edit3Icon, MoreHorizontalIcon, PlusSquareIcon, SaveIcon, Trash2Icon } from 'lucide-react';
import { TableLoader } from '@/components/ui/skeleton';
import { EmptyListAlert, ErrorAlert } from '@/components/ui/alert';
import { toast } from 'sonner';
import { ScrollArea } from '@/components/ui/scroll-area';

export function AddressListsList() {
  const lists = Loader.query<GetAddressLists>(
    gql`
      query GetAddressLists {
        addressLists {
          name
          count
        }
      }
    `,
  ).map(x => x.addressLists);
  const [newListName, setNewListName] = useState('');

  const nav = useNavigate();
  const apollo = useApolloClient();

  const newList = async () => {
    try {
      await apollo.mutate({
        mutation: gql`
          mutation CreateAddressList($name: String!) {
            newAddressList(name: $name)
          }
        `,
        variables: { name: newListName },
      });

      nav(`/address-lists/${encodeURIComponent(newListName)}`);
    } catch (e) {
      console.error('failed to create address list', e);
      toast.error('Failed to create address list');
    }
  };

  const deleteList = async (name: string) => {
    try {
      const wasDeleted = await apollo.mutate({
        mutation: gql`
          mutation AddressListDelete($name: String!) {
            addressListDelete(name: $name)
          }
        `,
        variables: { name },
      });
      if (wasDeleted) {
        toast.success(`${name} successfully deleted`)
      }
    } catch (error) {
      console.error('Failed to delete list', error);
      toast.error('Failed to delete list')
    }
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 pr-2 pl-4 pt-4 pb-8 items-center">
        <div className="flex-1 py-1">
          <h4>Addresses</h4>
        </div>
        <Dialog>
          <DialogTrigger asChild>
            <Button size="lg" className="flex items-center gap-2">
              <PlusSquareIcon className="w-4 h-4" />
              <span>New list</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>New address list</DialogTitle>
              <DialogDescription>Add new address list</DialogDescription>
            </DialogHeader>
            <div>
              <Input
                className="h-10 bg-secondary border-none rounded-xl"
                value={newListName}
                onChange={e => setNewListName(e.target.value)}
              />
            </div>
            <DialogFooter>
              <Button disabled={!newListName} size="lg" type="submit" className="flex items-center gap-2" onClick={() => newList()}>
                <SaveIcon className="w-4 h-4" />
                Save
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {lists.match
        .loadingOrSkipped(() => <TableLoader />)
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(list => (
          list.length === 0
            ? <EmptyListAlert message="No addresses list yet" />
            : (
              <ScrollArea>
                <Table className="border-separate border-spacing-y-2">
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      <TableHead>Creation date</TableHead>
                      <TableHead>Last update</TableHead>
                      <TableHead>Addresses</TableHead>
                      <TableHead />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {list.map(list => (
                      <TableRow data-type="primary" key={list.name} onClick={() => nav(`./${list.name}`)}>
                        <TableCell className="font-bold">{list.name}</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell>{list.count}</TableCell>
                        <TableCell />
                        <TableCell className="text-right pr-5">
                          <AlertDialog>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild className="self-center">
                                <Button variant="secondary" size="icon">
                                  <MoreHorizontalIcon className="w-4 h-4" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent onClick={e => e.stopPropagation()}>
                                <DropdownMenuItem className="gap-2.5" onClick={() => nav(`./${list.name}`)}>
                                  <Edit3Icon className="w-4 h-4" /> Edit
                                </DropdownMenuItem>
                                <DropdownMenuItem asChild className="gap-2.5 w-full" disabled={!!list.count}>
                                  <AlertDialogTrigger>
                                    <Trash2Icon className="w-4 h-4" />
                                    Delete
                                  </AlertDialogTrigger>
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                            <AlertDialogContent onClick={e => e.stopPropagation()}>
                              <AlertDialogHeader>
                                <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
                                <AlertDialogDescription>
                                  This action cannot be undone. This will permanently delete your script and remove your data
                                  from our servers.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction onClick={() => deleteList(list.name)}>Confirm</AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ScrollArea>)
        ))}
    </div>
  );
}
