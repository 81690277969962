import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"
import { CubeIcon, ExclamationTriangleIcon, InfoCircledIcon } from "@radix-ui/react-icons"
import { ReactNode } from "react"

const alertVariants = cva(
  "relative w-full rounded-lg border px-4 py-3 text-sm [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7",
  {
    variants: {
      variant: {
        default: "bg-transparent text-foreground",
        destructive:
          "border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
))
Alert.displayName = "Alert"

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn("mb-1 font-medium leading-none tracking-tight", className)}
    {...props}
  />
))
AlertTitle.displayName = "AlertTitle"

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm [&_p]:leading-relaxed", className)}
    {...props}
  />
))
AlertDescription.displayName = "AlertDescription"

type ErrorAlertProps = {
  name?: string;
  message?: string;
}

function ErrorAlert({ message = 'An unknown error occured', name = 'Unkown error' }: ErrorAlertProps) {
  return (
    <Alert variant="destructive">
      <ExclamationTriangleIcon className="h-4 w-4" />
      <AlertTitle>{name}</AlertTitle>
      <AlertDescription>
        {message}
      </AlertDescription>
    </Alert>
  )
}

type InfoAlertProps = {
  name?: string;
  message?: string;
  action?: ReactNode;
}

function InfoAlert({ message, name = 'Info' }: InfoAlertProps) {
  return (
    <Alert>
      <InfoCircledIcon className="h-4 w-4" />
      <AlertTitle>{name}</AlertTitle>
      <AlertDescription>
        {message}
      </AlertDescription>
    </Alert>
  )
}

function EmptyListAlert({ message, name = 'No data to display', action }: InfoAlertProps) {
  return (
    <Alert className="flex flex-col items-center justify-center h-full mb-2 min-h-72">
      <span><CubeIcon className="h-8 w-8 text-muted-foreground" /></span>
      <AlertTitle className="text-2xl">{name}</AlertTitle>
      <AlertDescription className="text-muted-foreground flex flex-col gap-2 items-center">
        {message}
        {action}
      </AlertDescription>
    </Alert>
  )
}

export { Alert, AlertTitle, AlertDescription, EmptyListAlert, ErrorAlert, InfoAlert }
