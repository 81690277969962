import { AndFilter, Filter } from './filterdef';
import { EventConstraintEditor } from './EventFilter/EventFilter';
import { AddressConstraintEdit } from './AddressConstraint';
import { AddConditions } from './AddConditions';
import { Fragment } from 'react';
import { PayloadTitle } from '@/components/ui/payload-title';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { Multiselect } from '@/components/ui/multiselect';
import { FormField } from '@/components/ui/form';
import { networkList } from '@/constants/networks';
import { Trash2Icon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

export function FilterEditor({
  filter,
  filterChange,
  readOnly,
}: {
  filter: Filter;
  filterChange?: (filter: Filter) => void;
  readOnly?: boolean;
}) {
  return <FEdit filter={filter} readOnly={readOnly} onChange={f => filterChange?.(f)} />;
}

function Or() {
  return (
    <div className="flex flex-col items-center gap-2.5 py-2">
      <div className="bg-border h-full w-px" />
      <div className="h-[30px] flex p-2 rounded-full bg-secondary items-center justify-center">OR</div>
      <div className="bg-border h-full w-px" />
    </div>
  )
}

function FEdit({ filter, onChange, readOnly }: { filter: Filter; readOnly?: boolean; onChange?: (filter: Filter) => void }) {

  const handleFilterChange = (value: AndFilter, index: number) => {
    const newFilter = [...filter];
    newFilter[index] = value;
    onChange?.(newFilter);
  }

  return (
    <div className="flex flex-row h-full">
      {filter.map((x, i) => (
        <Fragment key={i}>
          {readOnly && i > 0 && <Or />}
          <AndFilterEdit
            filter={x}
            index={i + 1}
            readOnly={readOnly}
            onChange={
              readOnly
                ? undefined
                : value => handleFilterChange(value, i)
            }
            onDelete={() => onChange?.(filter.filter((_, j) => i !== j))}
          />
          {!readOnly && <Or />}
        </Fragment>
      ))}
      {!readOnly && <AddConditions onClick={() => onChange?.([...filter, {}])} />}
    </div>
  );
}

function AndFilterEdit({
  filter,
  onChange,
  index,
  onDelete,
  readOnly,
}: {
  filter: AndFilter;
  onChange?: (filter: AndFilter) => void;
  onDelete?: VoidFunction;
  index: number;
  readOnly?: boolean;
}) {
  return (
    <div className="p-2">
      <div className="flex flex-col h-fit rounded-xl bg-secondary p-2 gap-2.5 w-[338px]">
        <PayloadTitle
          title={
            <div className="flex items-center gap-1">
              <span className="text-sm leading-[14px] font-medium">{`Filters #${index}`}</span>
              <InfoCircledIcon className="w-4 h-4" />
            </div>
          }
          actions={
            readOnly
              ? null
              : (
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button size="icon" variant="secondary" onClick={onDelete}>
                        <Trash2Icon className="w-4 h-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      Delete filter
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )
          }
          className="border-b w-full"
        />
        <div className="flex flex-col px-2 py-4 gap-4">
          <FormField value={filter.network} readOnly={readOnly} label="Network" onClear={() => onChange?.({ ...filter, network: undefined })}>
            <Multiselect
              options={networkList}
              readOnly={readOnly}
              selected={filter.network}
              placeholder="All networks"
              onChange={v => {
                onChange?.({
                  ...filter,
                  network: v.length ? v : undefined,
                });
              }}
            />
          </FormField>
          <FormField value={filter.from} readOnly={readOnly} label="Sent by" onClear={() => onChange?.({ ...filter, from: undefined })}>
            <AddressConstraintEdit
              constraint={filter.from}
              readOnly={readOnly}
              onChange={c => {
                onChange?.({ ...filter, from: c });
              }}
            />
          </FormField>
          <FormField value={filter.internalWrites} readOnly={readOnly} label="Has non-static call to" onClear={() => onChange?.({ ...filter, internalWrites: undefined })}>
            <AddressConstraintEdit
              constraint={filter.internalWrites}
              readOnly={readOnly}
              onChange={c => {
                onChange?.({ ...filter, internalWrites: c });
              }}
            />
          </FormField>
          <FormField value={filter.internalReads} readOnly={readOnly} label="Has static call to" onClear={() => onChange?.({ ...filter, internalReads: undefined })}>
            <AddressConstraintEdit
              constraint={filter.internalReads}
              readOnly={readOnly}
              onChange={c => {
                onChange?.({ ...filter, internalReads: c });
              }}
            />
          </FormField>
          <FormField value={filter.internalAny} readOnly={readOnly} label="Has call to" onClear={() => onChange?.({ ...filter, internalAny: undefined })}>
            <AddressConstraintEdit
              constraint={filter.internalAny}
              readOnly={readOnly}
              onChange={c => {
                onChange?.({ ...filter, internalAny: c });
              }}
            />
          </FormField>
          <FormField value={filter.to} readOnly={readOnly} label="To" onClear={() => onChange?.({ ...filter, to: undefined })}>
            <AddressConstraintEdit
              constraint={filter.to}
              readOnly={readOnly}
              onChange={c => {
                onChange?.({ ...filter, to: c });
              }}
            />
          </FormField>
          <FormField value={filter.logs} readOnly={readOnly} label="Has emitted" onClear={() => onChange?.({ ...filter, logs: undefined })}>
            <EventConstraintEditor
              constraint={filter.logs}
              readOnly={readOnly}
              onChange={c => {
                onChange?.({ ...filter, logs: c });
              }}
            />
          </FormField>
        </div>
      </div>
    </div>
  );
}
